<template>
    <admin-dashboard-layout>
        <v-card>
            <v-card-title>
                <v-row>
                    <v-col>
                        {{ bank.name }}
                    </v-col>
                    <v-col class="text-right">
                        <v-btn text :to="{name: 'admin.banks.index'}">BACK</v-btn>
                    </v-col>
                </v-row>
            </v-card-title>
            <v-card-text>
                <v-form ref="form">
                    <v-row>
                        <v-col>
                            <v-text-field label="Name in russian" placeholder="Please enter a name in russian" outlined v-model="bank.name_ru" ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-text-field label="Name in english" placeholder="Please enter a name in english" outlined v-model="bank.name_en" ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-text-field label="Name in armenian" placeholder="Please enter a name in armenian" outlined v-model="bank.name_hy" ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-text-field label="Slug" placeholder="Please enter a slug (english only)" outlined v-model="bank.slug" ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-text-field  placeholder="Please enter a description in armenian"  label="Description in armenian"
                                          v-model="bank.description_hy" outlined></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-text-field  placeholder="Please enter a description in russian" label="Description in russian"
                                          v-model="bank.description_ru" outlined ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-text-field   placeholder="Please enter a description in english" label="Description in english"
                                          v-model="bank.description_en" outlined ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-text-field label="Created at" v-model="bank.created_at" disabled></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <div>
                                <avatar-edit
                                    :current_url="bank.logo_url"
                                    @input="handleSaveBank"
                                    v-model="bank.logo_url"
                                ></avatar-edit>
                            </div>
                            <div>
                                <v-text-field label="Logo URL" v-model="bank.logo_url" disabled class="max-width200"></v-text-field>
                            </div>
                        </v-col>
                    </v-row>
                    <v-row v-if="bank.mortgages">
                        <v-list-item v-for="item in bank.mortgages" :key="item.id">
                            <v-row>
                                <v-col>
                                    <v-text-field label="Mortgage name" :rules="nameRules" disabled required outlined placeholder="Enter mortgage name" v-model="item.name"></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <v-text-field label="Min rate" v-model="item.min_rate" disabled></v-text-field>
                                </v-col>
                                <v-col>
                                    <v-text-field label="Max rate" v-model="item.max_rate" disabled></v-text-field>
                                </v-col>
                            </v-row>
                        </v-list-item>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-btn block color="green" @click="handleSaveBank">Save Changes</v-btn>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
        </v-card>
        <v-snackbar v-model="snackbar" color="green" :timeout="timeout_snackbar">{{ text_snackbar }}</v-snackbar>
    </admin-dashboard-layout>
</template>

<script>
import Bank from "../../../models/Bank";
import AdminDashboardLayout from "../../../layouts/AdminDashboardLayout";
import AvatarEdit from "../../../components/AvatarEdit";


export default {
    name: "banks-show",
    components: {AdminDashboardLayout,AvatarEdit},
    data: function () {
        return {
            bank: {},
            mortgages: false,
            formValid: true,
            nameRules: [v => !!v || 'Name is required'],
            snackbar: false,
            text_snackbar: 'Saved successfully',
            timeout_snackbar: 2000,
        }
    },
    methods:{
        async handleSaveBank() {
            this.formValid = this.$refs.form.validate()

            if (this.formValid) {
                const bank = new Bank(this.bank);
                this.bank = await bank.save()
                await this.$router.push({name: 'admin.banks.show', params: {id: this.bank.id}}).catch(()=>{})
                this.snackbar = true
            }
        }
    },
    async mounted() {
        const bankId = this.$route.params.id
        if (bankId) {
            this.bank = await Bank.with(['mortgages']).find(bankId)
        }
        if (this.bank.mortgages.length > 0) {
            this.mortgages = true
        }
    },
}
</script>

<style lang="scss" scoped>
.max-width200 {
    max-width: 200px;
}
</style>
